import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "gatsby";
import {
  Form,
  Button,
  Input,
  Checkbox,
  Select,
  DatePicker,
  Radio,
  Alert,
} from "antd";
import MaskedInput from "antd-mask-input";
import moment from "moment";
import "moment/locale/pl";
import locale from "antd/es/date-picker/locale/pl_PL";
// import "antd/dist/antd.css";
import NavCards from "@/components/profile/nav-cards";
import { ToastContainer, toast } from "react-toastify";
import { API, setUser, isLoggedIn } from "@/api/auth";

moment.locale("pl");

const PersonalInfo = ({ personalDataFilled, apiData }) => {
  const [state, setState] = useState({
    marketingClub: apiData ? apiData.marketingClub : 0,
    marketingPartners: apiData ? apiData.marketingPartners : 0,
    loadingSave: false,
    isLoaded: false,
  });
  const { marketingClub, marketingPartners, loadingSave, isLoaded } = state;
  const [formPersonalData] = Form.useForm();

  if (apiData) {
    apiData.birthdate = apiData.birthdate ? moment(apiData.birthdate) : null;
    if (isLoaded === false) {
      setState({
        ...state,
        isLoaded: true,
        marketingClub: apiData.marketingClub,
        marketingPartners: apiData.marketingPartners,
      });
      formPersonalData.setFieldsValue(apiData);
    }
  }

  const changeCheckbox = (name, e) => {
    setState({ ...state, [name]: e.target.checked ? 1 : 0 });
  };

  const savePersonalData = async values => {
    try {
      setState({ ...state, loadingSave: true });
      let obj = values;
      obj.marketingClub = marketingClub;
      obj.marketingPartners = marketingPartners;

      const response = await API.post(`/api/client/o/update`, obj);
      if (response.data.status == "success") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error(err);
    } finally {
      setState({ ...state, loadingSave: false });
    }
  };

  function setInputRef(e) {
    if (!this.inputRef.current) {
      this.inputRef.current = e;
    }
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ width: "400px" }}
      />
      <section className="commonSection personal-info-section">
        <Container className="personal-info-container">
          <Form
            form={formPersonalData}
            className="form align-items-stretch"
            size="large"
            onFinish={savePersonalData}
            onSubmit={e => e.preventDefault()}
          >
            <Row>
              <Col>
                <h4>Dane personalne</h4>

                <Form.Item name="email">
                  <Input
                    placeholder="E-mail"
                    disabled
                    rules={[
                      {
                        type: "email",
                        message: "To nie jest prawidłowy mail.",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item name="firstname">
                  <Input
                    placeholder="Imię"
                    disabled={apiData && apiData.firstname ? true : false}
                  />
                </Form.Item>
                <Form.Item name="lastname">
                  <Input
                    placeholder="Nazwisko"
                    disabled={apiData && apiData.lastname ? true : false}
                  />
                </Form.Item>
                <Form.Item name="birthdate">
                  <DatePicker
                    locale={locale}
                    format="DD.MM.YYYY"
                    placeholder="Wybierz datę urodzenia"
                    name="birthdate"
                    disabled={apiData && apiData.birthdate ? true : false}
                  />
                </Form.Item>
                <Form.Item name="pesel">
                  <Input
                    placeholder="PESEL"
                    disabled={apiData && apiData.pesel ? true : false}
                  />
                </Form.Item>
                <Form.Item name="gender">
                  <Radio.Group>
                    <Radio value={null}>nie określono</Radio>
                    <Radio value="male">mężczyzna</Radio>
                    <Radio value="female">kobieta</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col sm={12} md={12} lg={6} className="col-12">
                <h4>Dane kontaktowe</h4>
                <Form.Item name="phone">
                  <Input placeholder="Numer telefonu" />
                </Form.Item>
                <Form.Item name="address">
                  <Input placeholder="Ulica" />
                </Form.Item>
                <Form.Item name="postcode">
                  {/* <MaskedInput
                    name="postcode"
                    mask="00-000"
                    placeholder="Kod pocztowy"
                    inputRef={el => this.setInputRef(el)}
                  /> */}
                  <Input placeholder="Kod pocztowy" />
                </Form.Item>
                <Form.Item name="voivodeship">
                  <Select
                    showSearch
                    className="ms-auto"
                    placeholder="Wybierz województwo"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Option value="dolnośląskie">dolnośląskie</Option>
                    <Option value="kujawsko-pomorskie">
                      kujawsko-pomorskie
                    </Option>
                    <Option value="lubelskie">lubelskie</Option>
                    <Option value="lubuskie">lubuskie</Option>
                    <Option value="łódzkie">łódzkie</Option>
                    <Option value="małopolskie">małopolskie</Option>
                    <Option value="mazowieckie">mazowieckie</Option>
                    <Option value="opolskie">opolskie</Option>
                    <Option value="podkarpackie">podkarpackie</Option>
                    <Option value="podlaskie">podlaskie</Option>
                    <Option value="pomorskie">pomorskie</Option>
                    <Option value="śląskie">śląskie</Option>
                    <Option value="świętokrzyskie">świętokrzyskie</Option>
                    <Option value="warmińsko-mazurskie">
                      warmińsko-mazurskie
                    </Option>
                    <Option value="wielkopolskie">wielkopolskie</Option>
                    <Option value="zachodniopomorskie">
                      zachodniopomorskie
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item name="city">
                  <Input placeholder="Miejscowość" />
                </Form.Item>
              </Col>
              <Col className="personal-info-form-terms-col">
                <h4>Twoje zgody</h4>
                <Form.Item name="marketingClub">
                  <Checkbox
                    checked={marketingClub}
                    onChange={e => changeCheckbox("marketingClub", e)}
                  >
                    Wyrażam zgodę na otrzymywanie informacji marketingowych od
                    Klubu
                  </Checkbox>
                </Form.Item>
                <Form.Item name="marketingPartners">
                  <Checkbox
                    checked={marketingPartners}
                    onChange={e => changeCheckbox("marketingPartners", e)}
                  >
                    Wyrażam zgodę na otrzymywanie informacji marketingowych od
                    Partnerów Klubu
                  </Checkbox>
                </Form.Item>

                {apiData && !apiData.clientExId && (
                  <Alert
                    style={{ marginTop: "50px" }}
                    message="Brak integracji"
                    description="Twoje konto nie zostało zintegrowane z systemem biletowym, jeżeli posiadasz konto w systemie biletowym, podaj swój PESEL aby ukończyć integrację."
                    type="error"
                    showIcon
                  />
                )}
              </Col>
            </Row>
            <Button
              htmlType="submit"
              type="primary"
              className="btn__effect profile-button profile-button-red personal-info-button"
              disabled={loadingSave}
            >
              {loadingSave ? "Ładowanie..." : "Zapisz zmiany"}
            </Button>
          </Form>
        </Container>
      </section>
      <NavCards
        data={`default`}
        classNameNav="nav-cards-custom-pos"
        personalData={personalDataFilled}
      />
    </>
  );
};

export default PersonalInfo;
