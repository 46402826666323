import React from "react";
import { Router } from "@reach/router";
import Profile from "@/components/profile/pages/index";
import Login from "@/components/logowanie";
import PersonalInfo from "@/components/profile/pages/personal-info";
import PasswordChange from "@/components/profile/pages/change-password";
import JagaBenefit from "@/components/profile/pages/jaga-benefit";
import JagaShop from "@/components/profile/pages/jaga-shop";
import JagaTyper from "@/components/profile/pages/jaga-typer";
import JagaBilety from "@/components/profile/pages/jaga-bilety";
import ResetPwd from "@/components/resetowanie";
import PrivateRoute from "@/components/PrivateRoute";

const App = () => {
  return (
    <Router basepath="/klient">
      <PrivateRoute path="/profil" component={Profile} />
      <PrivateRoute path="/logowanie" component={Login} />
      <PrivateRoute path="/reset" component={ResetPwd} />
      <PrivateRoute path="/profil/twoje-dane" component={PersonalInfo} />
      <PrivateRoute path="/profil/zmiana-hasla" component={PasswordChange} />
      <PrivateRoute path="/profil/jaga-benefit" component={JagaBenefit} />
      <PrivateRoute path="/profil/jaga-shop" component={JagaShop} />
      <PrivateRoute path="/profil/jaga-typer" component={JagaTyper} />
      <PrivateRoute path="/profil/jaga-bilety" component={JagaBilety} />
      <PrivateRoute path="/" />
    </Router>
  );
};

export default App;
