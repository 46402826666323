import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import { isLoggedIn, logout } from "@/api/auth";

import BannerTopBg from "@/images/profile/banner-top-bg.png";
import defaultProfileImg from "@/images/profile/default-av.png";

const SliderTop = data => {
  const userData = data.data;

  return (
    <section className="profile-banner-top-section">
      <Container
        className="profile-banner-top-container"
        style={{ backgroundImage: `url('${BannerTopBg}')` }}
      >
        <Row className="justify-content-center align-items-center">
          <Col
            sm={12}
            md={12}
            lg={5}
            className="col-12 d-flex align-items-center justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start"
          >
            <Link
              to={`/klient/profil`}
              className="profile-banner-top-arrow-left"
            >
              {"<"}
            </Link>
            <img
              className="profile-banner-top-img mx-5"
              src={defaultProfileImg}
            />
            <Row className="flex-column justify-content-center">
              <h3 className="m-0 text-white text-uppercase profile-banner-name">
                {userData && userData.firstname
                  ? userData.firstname + " " + userData.lastname
                  : "Kibic"}
              </h3>
              <span className="text-white">{userData && userData.email}</span>
            </Row>
          </Col>
          <Col
            sm={12}
            md={12}
            lg={7}
            className="col-12 text-center text-sm-center text-md-center text-lg-right mt-3 mt-sm-3 mt-md-3 mt-lg-0"
          >
            <Link to="/klient/profil/twoje-dane">
              <button className="btn__effect profile-button">Twoje dane</button>
            </Link>
            <Link to="/klient/profil/zmiana-hasla">
              <button className="btn__effect profile-button mx-4">
                Zmień hasło
              </button>
            </Link>
            <Link to="#">
              <button
                className="btn__effect profile-button profile-button-dark"
                onClick={event => {
                  event.preventDefault();
                  logout();
                }}
              >
                Wyloguj
              </button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SliderTop;
