import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import NavCards from "@/components/profile/nav-cards";
import "moment/locale/pl";
import BannerImgBox from "@/components/profile/banner-img-box";

import ticketIcon from "@/images/icons/ticket-outline.svg";

const JagaShopContent = ({ data, personalDataFilled }) => {
  return (
    <section className="commonSection pt-0">
      <BannerImgBox ticketData={data && data} />
      <Container className="my-5 w-75">
        <Row className="jaga-benefit-points-details-title mb-2">
          <h5>Twoje bilety i karnety</h5>
        </Row>

        {data && data.tickets && (
          <Table
            borderless
            className="competitions-table tickets-table text-center"
          >
            <thead className="border-bottom">
              <tr>
                <th colspan="2" className="text-start ps-4"></th>
                <th>Data meczu</th>
                <th>Kwota</th>
              </tr>
            </thead>
            <tbody>
              {data.tickets.slice(0, 14).map((ticket, index) => (
                <tr className="jaga-benefit-points-details-box">
                  <td>
                    <img src={ticketIcon} />
                  </td>
                  <td className="text-start">{ticket.name}</td>
                  <td>{ticket.eventDate ? ticket.eventDate : "-"}</td>
                  <td className="fw-bold">{ticket.price} zł</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <Row className="justify-content-end">Pokazano 15 ostatnich wpisów</Row>
      </Container>
      <NavCards data={`offTickets`} personalData={personalDataFilled} />
    </section>
  );
};

export default JagaShopContent;
