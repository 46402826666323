import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import ticketBg from "@/images/profile/ticket-bg.jpg";

const ModalTicket = React.forwardRef(({ actualTicketData }, ref) => {
  return (
    <Container className="ticket-box p-0 h-auto" ref={ref}>
      {actualTicketData ? (
        <>
          <Row className="ticket-code-box justify-content-center p-3 m-0 text-center fs-5">
            <img src={actualTicketData.barcodeBase64} className="img-fluid" />
            {actualTicketData.barcodeText}
          </Row>
          <Row className="ticket-img-box m-0">
            <img src={ticketBg} className="img-fluid p-0" />
          </Row>
          <Row className="ticket-data-box m-0">
            <p>{actualTicketData.name}</p>
            {actualTicketData.eventDate && <p>{actualTicketData.eventDate}</p>}
            <p className="mt-3">
              {actualTicketData.ownerFirstname} {actualTicketData.ownerLastname}
            </p>
            <p className="fw-normal">{actualTicketData.ownerIdentifier}</p>
            <Row className="ticket-data-rows mt-3 p-0">
              <Col className="col-auto">
                SEKTOR
                <p>{actualTicketData.sectorCode}</p>
              </Col>
              <Col className="col-auto">
                RZĄD
                <p>{actualTicketData.posRow}</p>
              </Col>
              <Col className="col-auto">
                MIEJSCE
                <p>{actualTicketData.posNumber}</p>
              </Col>
              <Col className="col-auto">
                WEJŚCIE
                <p>{actualTicketData.entranceCode}</p>
              </Col>
            </Row>
          </Row>
        </>
      ) : (
        <Row>
          Wystąpił błąd podczas wczytywania biletu. Spróbuj ponownie później.
        </Row>
      )}
    </Container>
  );
});

export default ModalTicket;

// const ModalTicket = () => {
//   return (
//     <Container className="ticket-box p-0">
//       <Row className="ticket-code-box justify-content-center p-3 m-0">
//         <img src={exCode128} className="img-fluid w-auto" />
//       </Row>
//       <Row className="ticket-img-box m-0">
//         <img src={ticketBg} className="img-fluid p-0" />
//       </Row>
//       <Row className="ticket-data-box m-0">
//         <p>KARNET RUNDA JESIENNA 2022/23</p>
//         <p>16-07-2022</p>
//         <p className="mt-3">DAMIAN KANAPKA</p>
//         <p className="fw-normal">88000000000</p>
//         <Row className="ticket-data-rows mt-3 p-0">
//           <Col className="col-auto">
//             SEKTOR
//             <p>26</p>
//           </Col>
//           <Col className="col-auto">
//             RZĄD
//             <p>15</p>
//           </Col>
//           <Col className="col-auto">
//             MIEJSCE
//             <p>19</p>
//           </Col>
//           <Col className="col-auto">
//             WEJŚCIE
//             <p>K6</p>
//           </Col>
//         </Row>
//       </Row>
//     </Container>
//   );
// };
// export default ModalTicket;
