import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "gatsby";
import { Form, Button, Input, Checkbox } from "antd";
import NavCards from "@/components/profile/nav-cards";
import { ToastContainer, toast } from "react-toastify";
import { API, setUser, isLoggedIn } from "@/api/auth";

const PasswordChange = ({ personalDataFilled }) => {
  const [formChangePassword] = Form.useForm();

  const [state, setState] = useState({
    loadingSave: false,
  });
  const { loadingSave } = state;

  const savePasswordChange = async values => {
    try {
      setState({ ...state, loadingSave: true });
      const response = await API.post(`/api/client/o/changePassword`, values);
      if (response.data.status == "success") {
        toast.success(response.data.message);
        formChangePassword.resetFields();
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error(err);
    } finally {
      setState({ ...state, loadingSave: false });
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ width: "400px" }}
      />
      <section className="commonSection personal-info-section">
        <Container className="personal-info-container">
          <Form
            form={formChangePassword}
            className="form align-items-center change-password-form"
            size="large"
            onFinish={savePasswordChange}
          >
            <Row>
              <Col>
                <h4>Zmiana hasła</h4>
                <Form.Item
                  name="oldPassword"
                  rules={[
                    {
                      required: true,
                      message: "To pole jest wymagane.",
                    },
                  ]}
                >
                  <Input type="password" placeholder="Aktualne hasło" />
                </Form.Item>
                <Form.Item
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: "To pole jest wymagane.",
                    },
                  ]}
                >
                  <Input type="password" placeholder="Nowe hasło" />
                </Form.Item>
                <Form.Item
                  name="confirmNewPassword"
                  rules={[
                    {
                      required: true,
                      message: "To pole jest wymagane.",
                    },
                  ]}
                >
                  <Input type="password" placeholder="Powtórz hasło" />
                </Form.Item>
              </Col>
            </Row>
            <Button
              htmlType="submit"
              type="primary"
              className="btn__effect profile-button profile-button-red personal-info-button"
              disabled={loadingSave}
            >
              {loadingSave ? "Ładowanie..." : "Zapisz zmiany"}
            </Button>
          </Form>
        </Container>
      </section>
      <NavCards
        data={`default`}
        classNameNav="nav-cards-custom-pos"
        personalData={personalDataFilled}
      />
    </>
  );
};

export default PasswordChange;
