import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavCards from "@/components/profile/nav-cards";
import Moment from "react-moment";

const JagaBenefitContent = ({ personalDataFilled, benefit }) => {
  return (
    <section className="commonSection py-0 py-sm-0 py-md-0 py-lg-5">
      <Container className="my-5 w-75">
        <Row className="jaga-benefit-points-details-title">
          <h5>Historia Twoich punktów</h5>
        </Row>

        {benefit && benefit.length > 0 ? (
          <>
            {benefit.map((b, i) => {
              return (
                <Row className="jaga-benefit-points-details-box align-items-center justify-content-center text-center">
                  <Col className="col-auto mx-0 jaga-benefit-points-detail-icon-col">
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                  </Col>
                  <Col
                    sm={12}
                    md={12}
                    lg={"auto"}
                    className="col-auto ms-0 me-5 fw-bold py-3 py-sm-3 py-md-3 py-lg-0"
                  >
                    {b.description}
                  </Col>
                  <Col
                    sm={6}
                    md={6}
                    lg={"auto"}
                    className="col-6 ms-0 ms-sm-0 ms-md-auto ms-lg-auto fw-bold"
                  >
                    <Moment date={b.created_at} format="DD MMMM YYYY"></Moment>
                  </Col>
                  <Col
                    sm={6}
                    md={6}
                    lg={"auto"}
                    className="col-auto ms-0 text-end fw-bold text-center"
                    style={b.points >= 0 ? { color: "green" } : { color: "red" }}
                  >
                    {b.points >= 0 ? `+ ${b.points} pkt` : ` ${b.points} pkt`}
                  </Col>
                </Row>
              )
            })}
          </>
        ) : (
          <Row className="jaga-benefit-points-details-box align-items-center justify-content-center text-center">
            <Col className="col-auto mx-0 jaga-benefit-points-detail-icon-col">
              <i className="fa fa-plus-circle" aria-hidden="true"></i>
            </Col>
            <Col
              sm={6}
              md={6}
              lg={"auto"}
              className="text-center"
            >
              Historia Twoich punktów jest pusta
            </Col>
          </Row>
        )}

        {/*<Row className="jaga-benefit-points-details-box align-items-center justify-content-center text-center">
          <Col className="col-auto mx-0 jaga-benefit-points-detail-icon-col">
            <i className="fa fa-plus-circle" aria-hidden="true"></i>
          </Col>
          <Col
            sm={12}
            md={12}
            lg={"auto"}
            className="col-auto ms-0 fw-bold py-3 py-sm-3 py-md-3 py-lg-0"
          >
            Obecność na meczu Jagiellonia - Wisła K.
          </Col>
          <Col
            sm={6}
            md={6}
            lg={"auto"}
            className="col-6 ms-0 ms-sm-0 ms-md-auto ms-lg-auto fw-bold"
          >
            27.04.2022
          </Col>
          <Col
            sm={6}
            md={6}
            lg={"auto"}
            className="col-auto ms-0 text-end fw-bold text-center"
            style={{ color: "green" }}
          >
            + 40 pkt
          </Col>
        </Row>*/}
      </Container>
      <NavCards data={`offBenefit`} personalData={personalDataFilled} />
    </section>
  );
};

export default JagaBenefitContent;
