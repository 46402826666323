import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavCards from "@/components/profile/nav-cards";
import Moment from "react-moment";

const JagaShopContent = ({ personalDataFilled, transactions }) => {

  return (
    <section className="commonSection py-0 py-sm-0 py-md-0 py-lg-5">
      <Container className="my-5 w-75">
        <Row className="jaga-benefit-points-details-title">
          <h5>Historia Twoich transakcji</h5>
        </Row>

        {transactions && transactions.length > 0 ? (
          <>
            {transactions.map((t, i) => {
              return (
                <>
                  <Row className="jaga-benefit-points-details-box align-items-center">
                    <Col className="col-auto mx-0 jaga-benefit-points-detail-icon-col">
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    </Col>
                    <Col className="col-auto ms-0 fw-bold">{t.description}</Col>
                    <Col className="col-auto ms-auto fw-bold"><Moment date={t.dateCreated} format="DD MMMM YYYY"></Moment></Col>
                    <Col
                      className="col-auto ms-0 text-end fw-bold"
                      style={t.points >= 0 ? { color: "green" } : { color: "red" }}
                    >
                      {t.points >= 0 ? `+ ${t.points} pkt` : `- ${t.points} pkt`}
                    </Col>
                  </Row>
                </>
              )
            })}
          </>
        ) : (
          <Row className="jaga-benefit-points-details-box align-items-center">
            <Col className="col-auto mx-0 jaga-benefit-points-detail-icon-col">
              <i className="fa fa-plus-circle" aria-hidden="true"></i>
            </Col>
            <Col className="text-center">Historia Twoich transakcji jest pusta</Col>
          </Row>
        )}
      </Container>
      <NavCards data={`offShop`} personalData={personalDataFilled} />
    </section>
  );
};

export default JagaShopContent;
