import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import NavCards from "@/components/profile/nav-cards";
import Countdown, { zeroPad } from "react-countdown";
import Moment from "react-moment";
import "moment/locale/pl";
import Swal from "sweetalert2";
import { API } from "@/api/auth";
import ballIcon from "@/images/icons/ball.svg";

const JagaShopContent = ({ personalDataFilled, matchroom, bets }) => {
  let exampleDate = null;
  let renderer = null;
  let bet = null;

  const [state, setState] = useState({
    bets: [],
  });

  useEffect(() => {
    if (bets && Array.isArray(bets)) setState({ ...state, bets });
  }, [bets]);

  if (matchroom) {
    exampleDate = matchroom.matchDate;

    bet = v => {
      const questionMap = [
        {
          o: "1",
          q: `Czy na pewno chcesz obstawić wygraną drużyny ${matchroom.homeConName}?`,
        },
        { o: "X", q: `Czy na pewno chcesz obstawić remis w tym meczu?` },
        {
          o: "2",
          q: `Czy na pewno chcesz obstawić wygraną drużyny ${matchroom.awayConName}?`,
        },
      ];

      if (
        state.bets.length > 0 &&
        state.bets.find(v => v.matchroomId == matchroom.id)
      )
        return;

      Swal.fire({
        title: "Wytypuj wynik",
        text: questionMap.find(x => x.o == v).q,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Nie",
        confirmButtonText: "Tak",
      }).then(async result => {
        if (result.isConfirmed) {
          const { data } = await API.post("/api/matchroom/bet", {
            matchroomId: matchroom.id,
            bet: v,
          });

          if (data.status == "success") {
            const preBets = state.bets;
            preBets.push(data.object);
            setState({ ...state, bets: preBets });

            Swal.fire("Udało się", data.message, "success");
          } else Swal.fire("Wystąpił błąd", data.message, "error");
        }
      });
    };

    renderer = ({ days, hours, minutes, seconds }) => (
      <div>
        {matchroom.statusId == 1 ? (
          <>
            <div className="text-center mb-2 jaga-typer-details-box-time">
              <Moment date={exampleDate} format="LLL" />
            </div>
            <Row
              className="text-center countdown"
              style={{ marginLeft: "15px" }}
            >
              <Col lg={2} md={2} sm={2} className="col-2">
                {zeroPad(days)}
                <div className="countdown-bottom">Dni</div>
              </Col>
              <Col className="col-1">:</Col>
              <Col lg={2} md={2} sm={2} className="col-2">
                {zeroPad(hours)}
                <div className="countdown-bottom">Godz.</div>
              </Col>
              <Col className="col-1">:</Col>
              <Col lg={2} md={2} sm={2} className="col-2">
                {zeroPad(minutes)}
                <div className="countdown-bottom">Min.</div>
              </Col>
              <Col className="col-1">:</Col>
              <Col lg={2} md={2} sm={2} className="col-2">
                {zeroPad(seconds)}
                <div className="countdown-bottom">Sek.</div>
              </Col>
            </Row>
          </>
        ) : (
          <div
            className="text-center mb-2 jaga-typer-details-box-time"
            style={{
              marginTop: "-25px",
              borderRadius: "12px",
              background: "#d81921",
              color: "white",
              padding: "5px 20px",
              fontSize: "20px",
            }}
          >
            Typowanie zakończone
          </div>
        )}
      </div>
    );
  }

  return (
    <section className="commonSection pt-0">
      {!matchroom ? (
        <Container className="w-75 jaga-typer-details-box-container text-center">
          Ładowanie spotkania...
        </Container>
      ) : (
        <Container
          className={`w-75 jaga-typer-details-box-container ${
            state.bets.length > 0 &&
            state.bets.find(v => v.matchroomId == matchroom.id) &&
            "jaga-typer-is-betted"
          }`}
        >
          <Row className="flex-column text-center jaga-typer-details-box-titles">
            <h5 style={{ textTransform: "uppercase" }}>
              {matchroom.leagueName}
            </h5>
            <h6
              style={{ textTransform: "uppercase" }}
              className="mt-3 mt-sm-3 mt-md-3 mt-lg-0"
            >
              {matchroom.leagueId == 0
                ? `${matchroom.week}. kolejka`
                : matchroom.week}
            </h6>
          </Row>
          <Row className="justify-content-center align-items-center text-center">
            <Col className="order-1">
              <div className="rounded-circle fw-bold">
                <img
                  src={`${process.env.GATSBY_API_BASE_URL}/${matchroom.homeCrestImage.path}`}
                  className="mb-4"
                  alt="home crest"
                />
              </div>
              <span style={{ textTransform: "uppercase" }} className="fw-bold">
                {matchroom.homeConName}
              </span>
            </Col>
            <Col
              sm={12}
              md={12}
              lg="auto"
              className={`order-3 order-sm-3 order-md-3 order-lg-2 mt-5 mt-sm-5 mt-md-5 ${
                matchroom.statusId == 1 ? "mt-lg-auto" : ""
              }`}
            >
              <Countdown
                className="countdown w-50"
                date={matchroom && exampleDate}
                renderer={matchroom && renderer}
              />
            </Col>
            <Col className="order-2 order-sm-2 order-md-2 order-lg-3">
              <div className="rounded-circle fw-bold">
                <img
                  src={`${process.env.GATSBY_API_BASE_URL}/${matchroom.awayCrestImage.path}`}
                  className="mb-4"
                  alt="away crest"
                />
              </div>
              <span style={{ textTransform: "uppercase" }} className="fw-bold">
                {matchroom.awayConName}
              </span>
            </Col>
          </Row>
          {matchroom.statusId == 1 && (
            <>
              <Row className="flex-column text-center mt-5 fw-bold">
                WYTYPUJ WYNIK
              </Row>
              <Row className="text-center mt-4 fw-bold justify-content-center">
                <div
                  className={`rounded-circle jaga-typer-details-box-bet ${
                    state.bets.length > 0 &&
                    state.bets.find(v => v.matchroomId == matchroom.id)?.bet ==
                      "1" &&
                    "jaga-typer-active"
                  }`}
                  onClick={() => bet("1")}
                >
                  1
                </div>
                <div
                  className={`rounded-circle jaga-typer-details-box-bet ${
                    state.bets.length > 0 &&
                    state.bets.find(v => v.matchroomId == matchroom.id)?.bet ==
                      "X" &&
                    "jaga-typer-active"
                  }`}
                  onClick={() => bet("X")}
                >
                  X
                </div>
                <div
                  className={`rounded-circle jaga-typer-details-box-bet ${
                    state.bets.length > 0 &&
                    state.bets.find(v => v.matchroomId == matchroom.id)?.bet ==
                      "2" &&
                    "jaga-typer-active"
                  }`}
                  onClick={() => bet("2")}
                >
                  2
                </div>
              </Row>
            </>
          )}
          {state.bets.length > 0 &&
            state.bets.find(v => v.matchroomId == matchroom.id) && (
              <Row className="text-center mt-4 fw-bold justify-content-center">
                <div className="jaga-typer-details-prc">
                  {matchroom.betstats.homeBetPrc}
                </div>
                <div className="jaga-typer-details-prc">
                  {matchroom.betstats.drawBetPrc}
                </div>
                <div className="jaga-typer-details-prc">
                  {matchroom.betstats.awayBetPrc}
                </div>
              </Row>
            )}
        </Container>
      )}
      <Container className="my-5 w-75">
        <Row className="jaga-benefit-points-details-title">
          <h5>Twoje typowania</h5>
        </Row>

        <Table
          borderless
          className="competitions-table typer-table text-center"
        >
          <thead className="border-bottom">
            <tr>
              <th colSpan="2" className="text-start ps-4">
                Mecz
              </th>
              <th>Data meczu</th>
              <th>Twój typ</th>
              <th>Wynik</th>
            </tr>
          </thead>
          <tbody>
            {state.bets && state.bets.length == 0 && (
              <tr className="jaga-benefit-points-details-box">
                <td colSpan="5">Lista Twoich zakładów jest pusta</td>
              </tr>
            )}
            {state.bets && state.bets.length > 0 && (
              <>
                {state.bets.map((b, i) => (
                  <tr className="jaga-benefit-points-details-box" key={i}>
                    <td>
                      <img src={ballIcon} alt="" />
                    </td>
                    <td className="text-start">
                      {b.matchroom.homeConName} - {b.matchroom.awayConName}
                    </td>
                    <td>
                      <Moment
                        date={b.matchroom.matchDate}
                        format="DD MMMM YYYY"
                      />
                    </td>
                    <td className="fw-bold">{b.bet}</td>
                    <td
                      className="fw-bold"
                      style={
                        b.isWon == 1
                          ? { color: "green" }
                          : b.isWon === 0
                          ? { color: "red" }
                          : {}
                      }
                    >
                      {b.matchroom.homeGoals}:{b.matchroom.awayGoals}
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </Table>
      </Container>
      <NavCards data="offBetting" personalData={personalDataFilled} />
    </section>
  );
};

export default JagaShopContent;
