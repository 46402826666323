import "@/css/profile.css";
import React, { useEffect, useState } from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderOneTop from "@/components/header-one-top";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import BannerTop from "@/components/profile/banner-top";
import PersonalInfo from "@/components/profile/personal-info";
import { API, isLoggedIn } from "@/api/auth";

const PersonalInfoPage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    if (isLoggedIn()) {
      try {
        setLoading(true);
        const response = await API.get("/api/client/o/get");

        if (response.data.status == "error") logout();
        else setData(response.data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Twoje dane - Profil kibica">
          <HeaderOneTop />
          <HeaderOne />
          <BannerTop data={data} />
          <PersonalInfo
            personalDataFilled={data && data.clientExId ? true : false}
            apiData={data && data}
          />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default PersonalInfoPage;
