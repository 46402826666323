import React from "react";

import JagaShopBg from "@/images/profile/shop-bg.jpg";

const JagaShopTopContent = ({ personalDataInfo }) => {
  return (
    <section className="commonSection">
      <img src={JagaShopBg} className="main-content-bg jaga-benefit-bg" />
      <div className="text-center jaga-benefit-top-content">
        <h2>JAGA SHOP</h2>
        <span>Aktualna liczba Twoich punktów</span>
        <div className="rounded-circle jaga-benefit-points-box">
          <h3>
            {personalDataInfo ? Math.round(personalDataInfo.points) : "-"}
          </h3>
          <span>pkt</span>
        </div>
      </div>
    </section>
  );
};

export default JagaShopTopContent;
