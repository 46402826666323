import React, { useState, useEffect } from "react";
import "@/css/login.css";
import { Link, navigate } from "gatsby";
import { ToastContainer, toast } from "react-toastify";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useQueryParam, StringParam } from "use-query-params";
import { Form, Button, Input, Checkbox } from "antd";
import logo from "@/images/v-logo.png";
import sliderBg from "@/images/bg/matchroom/top.jpg";
import formBg from "@/images/login/login-form-bg.jpg";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import api from "@/axios";
import { setUser } from "@/api/auth";

const Login = () => {
  const [paramHash, setParamHash] = useQueryParam("hash", StringParam);
  const [isActive, setIsActive] = useState(false);
  const [checkedCheckbox, setIsCheckedCheckbox] = useState(false);
  const handleClick = () => {
    setIsActive(current => !current);
  };
  const [isActiveRemember, setIsActiveRemember] = useState(false);
  const handleClickRemember = () => {
    setIsActiveRemember(current => !current);
  };

  useEffect(() => {
    if (paramHash) {
      const ResData = async () => {
        try {
          const response = await api.post(`/client/confirm`, {
            confirmationHash: paramHash,
          });

          if (response.data.status == "success") {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        } catch (err) {
          toast.error(err);
        }
      };
      ResData();
    }
  }, []);

  const [formLogin] = Form.useForm();
  const [formRegister] = Form.useForm();
  const [formRemember] = Form.useForm();

  // API
  const [state, setState] = useState({
    loading: false,
    data: null,
  });

  const reset = async formValues => {
    try {
      const response = await api.post(`/client/sendResetPassword`, formValues);
      if (response.data.status == "success") {
        toast.success(response.data.message);
        setIsActiveRemember(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const login = async formValues => {
    try {
      const response = await api.post(`/client/signin`, formValues);
      if (response.data.token) {
        setUser(response.data);
        navigate("/klient/profil");
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const register = async formValues => {
    try {
      const response = await api.post(`/client/register`, formValues);
      if (response.data.status == "success") {
        toast.success(response.data.message);
        formRegister.resetFields();
        setIsActive(false);
        setIsCheckedCheckbox(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const onFinish = formName => {
    if (formName == "reset") {
      reset(formRemember.getFieldsValue());
    }
    if (formName == "login") {
      login(formLogin.getFieldsValue());
    }
    if (formName == "register") {
      checkedCheckbox
        ? register(formRegister.getFieldsValue())
        : toast.error(
            "Aby zarejestrować konto zaakceptuj regulamin w formularzu."
          );
    }
  };

  const onChangeCheckboxReg = e => {
    setIsCheckedCheckbox(e.target.checked);
  };

  return (
    <div
      className="body__form"
      style={{ backgroundImage: `url('${sliderBg}')` }}
    >
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ width: "400px" }}
      />
      <div
        className={`container_login animated fadeInDown ${
          isActive ? "right-panel-active" : ""
        }`}
      >
        <div className="container__form container--signup">
          <Form
            style={{ width: "100%" }}
            form={formRegister}
            onFinish={() => onFinish("register")}
            className="form"
            size="large"
          >
            <Link to="/">
              <img
                src={logo}
                className="img-fluid mb-3 logos-size"
                alt="logo"
              />
            </Link>
            <h2 className="form__title">Zarejestruj się</h2>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "To pole jest wymagane.",
                },
                {
                  type: "email",
                  message: "To nie jest prawidłowy mail.",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Adres e-mail"
                className="input"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "To pole jest wymagane.",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Hasło"
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "To pole jest wymagane.",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Powtórz hasło"
              />
            </Form.Item>
            <div className="d-flex align-items-center w-100 px-4 pt-0 mt-2">
              <Checkbox onChange={onChangeCheckboxReg}>
                <small>
                  Przeczytałem i akceptuję regulamin{" "}
                  <a
                    href={`${process.env.GATSBY_API_BASE_URL}/upload/mediafiles/regulaminy/RegulaminKontaKibica.pdf`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    konta kibica
                  </a>
                </small>
              </Checkbox>
            </div>
            <Button
              htmlType="submit"
              type="primary"
              className="btn__effect mt-3"
            >
              Zarejestruj się
            </Button>
            <div className="show-mobile-register">
              Masz już konto?{" "}
              <a href="" className="" onClick={handleClick}>
                Zaloguj się
              </a>
            </div>
          </Form>
        </div>

        <div
          className={`container__form container--signin fadeInDown ${
            isActiveRemember ? "d-block" : "d-none"
          }`}
        >
          <Form
            form={formRemember}
            onFinish={() => onFinish("reset")}
            style={{ width: "100%" }}
            className="form"
            size="large"
          >
            <img src={logo} className="img-fluid mb-3 logos-size" alt="logo" />
            <h2 className="form__title">Resetowanie hasła</h2>
            <p>
              Podaj adres e-mail użyty przy rejestracji, a następnie sprawdź
              pocztę.
            </p>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "To pole jest wymagane.",
                },
                {
                  type: "email",
                  message: "To nie jest prawidłowy mail.",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Adres e-mail"
                className="input"
              />
            </Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              className="btn__effect mt-3 login-remember-password-buttons"
            >
              Wyślij
            </Button>
            <Button
              type="primary"
              className="btn__effect mt-3 login-remember-password-buttons"
              onClick={handleClickRemember}
            >
              Anuluj
            </Button>
          </Form>
        </div>

        <div
          className={`container__form container--signin fadeInDown ${
            isActiveRemember ? "d-none" : "d-block"
          }`}
        >
          <Form
            style={{ width: "100%" }}
            form={formLogin}
            onFinish={() => onFinish("login")}
            className="form"
            size="large"
          >
            <img src={logo} className="img-fluid mb-3 logos-size" alt="logo" />
            <h2 className="form__title">Zaloguj się</h2>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "To pole jest wymagane.",
                },
                {
                  type: "email",
                  message: "To nie jest prawidłowy mail.",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Adres e-mail"
                className="input"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "To pole jest wymagane.",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Hasło"
              />
            </Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              className="btn__effect mt-3"
            >
              Zaloguj się
            </Button>
            <div className="mt-3">
              Nie pamiętasz hasła?{" "}
              <span
                className="login-remember-password-span"
                onClick={handleClickRemember}
              >
                Resetuj hasło
              </span>
            </div>
            <div className="show-mobile-register">
              Nie masz konta?{" "}
              <a href="" className="" onClick={handleClick}>
                Dołącz do nas
              </a>
            </div>
          </Form>
        </div>

        <div className="container__overlay">
          <div
            className="overlay"
            style={{ backgroundImage: `url('${formBg}')` }}
          >
            <div className="overlay__panel overlay--left">
              <button className="btn__effect" onClick={handleClick}>
                Zaloguj się
              </button>
            </div>
            <div className="overlay__panel overlay--right">
              <button
                type="button"
                className="btn__effect"
                onClick={handleClick}
              >
                Dołącz do nas
              </button>
            </div>
          </div>
        </div>
        <Link to="/" className="profile-banner-top-arrow-left login-arrow-left">
          {"<"}
          <span className="login-arrow-redirect-span">Strona główna</span>
        </Link>
      </div>
    </div>
  );
};

export default Login;
