import "@/css/profile.css";
import React, { useState, useEffect } from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderOneTop from "@/components/header-one-top";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import BannerTop from "@/components/profile/banner-top";
import TopContent from "@/components/profile/jaga-typer-top-content";
import MainContent from "@/components/profile/jaga-typer-main-content";
import { API, isLoggedIn } from "@/api/auth";
import { Link, navigate } from "gatsby";

const JagaBenefit = () => {
  const [data, setData] = useState(null);
  const [matchroom, setMatchroom] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMatchroom, setLoadingMatchroom] = useState(false);

  const getData = async () => {
      try {
        setLoading(true);
        const response = await API.get("/api/client/o/get");

        if (!response.data.clientExId) {
          navigate("/klient/profil");
        }

        if (response.data.status == "error") logout();
        else setData(response.data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
  };

  const getMatchroom = async () => {
      try {
        setLoadingMatchroom(true);
        const { data } = await API.get(`/api/matchroom/tobet`);

        if(!data.status) setMatchroom(data);
      } catch (err) {
      } finally {
        setLoadingMatchroom(false);
      }
  }

  useEffect(() => {
    getData();
    getMatchroom();
  }, []);

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Jaga typer - Profil kibica">
          <HeaderOneTop />
          <HeaderOne />
          <BannerTop data={data} />
          <TopContent />
          <MainContent
            personalDataFilled={data && data.clientExId ? true : false}
            matchroom={matchroom}
            bets={data && data.bets}
          />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default JagaBenefit;
