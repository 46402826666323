import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import "@/css/login.css";
import logo from "@/images/v-logo.png";
import sliderBg from "@/images/bg/matchroom/top.jpg";
import { Link, navigate } from "gatsby";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Button, Input, Checkbox } from "antd";
import "antd/dist/antd.css";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import api from "@/axios";
import { API, setUser, isLoggedIn } from "@/api/auth";
import { useQueryParam, StringParam } from "use-query-params";

const ResetPwd = () => {
  const [state, setState] = useState({
    loading: true,
    data: null,
    message: ""
  });
  const [paramHash, setParamHash] = useQueryParam("hash", StringParam);

  useEffect(() => {
    if (paramHash) {
      const ResData = async () => {
        try {
          const response = await api.post(`/client/resethash`, {
            confirmationHash: paramHash,
          });

          if (response.data.status != "success") {
            setTimeout(() => {
              setState({ ...state, message: response.data.message });
            }, 1000);

            setTimeout(() => {
              navigate("/klient/logowanie");
            }, 5000);
          } else setState({ ...state, loading: false });
        } catch (err) {
          navigate("/klient/logowanie");
        }
      };
      ResData();
    } else navigate("/klient/logowanie");
  }, []);

  const [formReset] = Form.useForm();

  const { loading, message } = state;

  const resetPassword = async formValues => {
    let data = { ...formValues, confirmationHash: paramHash }

    try {
      const response = await api.post(`/client/o/resetPassword`, data);

      if (response.data.status == 'success') {
        toast.success(response.data.message);
        setState({ ...state, loading: true, message: 'Przenoszenie...' });

        setTimeout(() => {
          navigate("/klient/logowanie");
        }, 5000);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const onFinish = () => {
    resetPassword(formReset.getFieldsValue())
  };

  return (
    <div
      className="body__form"
      style={{ backgroundImage: `url('${sliderBg}')` }}
    >
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ width: "400px" }}
      />

      <div
        className={`container_login container_reset`}
      >

        <div
          className={`container__form container--reset`}
        >
          {!loading ? (
            <Form
            style={{ width: "100%" }}
            form={formReset}
            onFinish={onFinish}
            className="form"
            size="large"
          >
            <img src={logo} className="img-fluid mb-3 logos-size" />
            <h2 className="form__title">Resetowanie hasła</h2>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "To pole jest wymagane.",
                }
              ]}
            >
              <Input
                type="password"
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Nowe hasło"
                className="input"
              />
            </Form.Item>
            <Form.Item
              name="cpassword"
              rules={[
                {
                  required: true,
                  message: "To pole jest wymagane.",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Powtórz nowe hasło"
              />
            </Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              className="btn__effect mt-3"
            >
              Potwierdź
            </Button>
          </Form>
          ) : (
            <Row className="py-5 w-75 mx-auto" style={{ marginTop: '180px' }}>
              {message.length === 0 ? (
                <>
                  <span className="loader"></span>
                  <span className="text-center mt-5">Weryfikowanie danych użytkownika</span>
                </>
              ) : (
                <>
                  {message === 'Przenoszenie...' && <span className="loader"></span>}
                  <span className={`text-center ${message !== 'Przenoszenie...' && 'text-red'} mt-5`}>{message === 'Przenoszenie...' ? 'Nowe hasło zostało zapisane, za chwile zostaniesz przekierowany do strony logowania.' : message}</span>
                </>
              )}
            </Row>
          )}
        </div>
        <Link to="/" className="profile-banner-top-arrow-left login-arrow-left">
          {"<"}
          <span className="login-arrow-redirect-span">Strona główna</span>
        </Link>
      </div>
    </div>
  );
};

export default ResetPwd;
