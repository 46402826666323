import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "gatsby";

import benefitBg from "@/images/profile/nav-card-benefit-bg.jpg";
import ticketsBg from "@/images/profile/nav-card-tickets-bg.jpg";
import bettingBg from "@/images/profile/nav-card-betting-bg.jpg";
import shopBg from "@/images/profile/nav-card-shop-bg.jpg";

const NavCards = ({ data, classNameNav, personalData }) => {
  return (
    <Container className={`w-75` && classNameNav && `${classNameNav}`}>
      <Row className="justify-content-center">
        {/* BENEFIT */}
        {data && !data.includes("offBenefit") && (
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3">
            <div
              className={`singleTM tickets-card ${
                !personalData && "grayscale"
              }`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ backgroundImage: `url('${benefitBg}')` }}
              >
                <div className="tm_overlay"></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <h3>JAGA BENEFIT</h3>
                  <span>SPRAWDŹ SWOJE PUNKTY</span>
                  {personalData ? (
                    <Link
                      to="/klient/profil/jaga-benefit"
                      className="btn__effect profile-button profile-button-red nav-cards-button"
                    >
                      SZCZEGÓŁY
                    </Link>
                  ) : (
                    <Link
                      to="/klient/profil/twoje-dane"
                      className="btn__effect profile-button profile-button-red nav-cards-button"
                    >
                      ODBLOKUJ DOSTĘP
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </Col>
        )}
        {/* BILETY */}
        {data && !data.includes("offTickets") && (
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3">
            <div
              className={`singleTM tickets-card ${
                !personalData && "grayscale"
              }`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ backgroundImage: `url('${ticketsBg}')` }}
              >
                <div className="tm_overlay"></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <h3>BILETY KARNETY</h3>
                  <span>HISTORIA ZAKUPU BILETÓW I KARNETÓW</span>
                  {personalData ? (
                    <Link
                      to="/klient/profil/jaga-bilety"
                      className="btn__effect profile-button profile-button-red nav-cards-button"
                    >
                      SZCZEGÓŁY
                    </Link>
                  ) : (
                    <Link
                      to="/klient/profil/twoje-dane"
                      className="btn__effect profile-button profile-button-red nav-cards-button"
                    >
                      ODBLOKUJ DOSTĘP
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </Col>
        )}
        {/* TYPER */}
        {data && !data.includes("offBetting") && (
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3">
            <div
              className={`singleTM tickets-card ${
                !personalData && "grayscale"
              }`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ backgroundImage: `url('${bettingBg}')` }}
              >
                <div className="tm_overlay"></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <h3>JAGA TYPER</h3>
                  <span>WYTYPUJ WYNIK NAJBLIŻSZEGO MECZU</span>
                  {personalData ? (
                    <Link
                      to="/klient/profil/jaga-typer"
                      className="btn__effect profile-button profile-button-red nav-cards-button"
                    >
                      SZCZEGÓŁY
                    </Link>
                  ) : (
                    <Link
                      to="/klient/profil/twoje-dane"
                      className="btn__effect profile-button profile-button-red nav-cards-button"
                    >
                      ODBLOKUJ DOSTĘP
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </Col>
        )}
        {/* SKLEP */}
        {data && !data.includes("offShop") && (
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3">
            <div
              className={`singleTM tickets-card ${
                "grayscale"
              }`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ backgroundImage: `url('${shopBg}')` }}
              >
                <div className="tm_overlay"></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <h3>JAGA FAN SHOP</h3>
                  <span>TWOJE ZAKUPY</span>
                  {/*{personalData ? (
                    <Link
                      to="/klient/profil/jaga-shop"
                      className="btn__effect profile-button profile-button-red nav-cards-button"
                    >
                      SZCZEGÓŁY
                    </Link>
                  ) : (
                    <Link
                      to="/klient/profil/twoje-dane"
                      className="btn__effect profile-button profile-button-red nav-cards-button"
                    >
                      ODBLOKUJ DOSTĘP
                    </Link>
                  )}*/}
                  <Link
                      to="/klient/profil"
                      className="btn__effect profile-button profile-button-red nav-cards-button"
                    >
                      NIEAKTYWNY
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default NavCards;
