import "@/css/profile.css";
import React, { useState, useEffect } from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderOneTop from "@/components/header-one-top";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import BannerTop from "@/components/profile/banner-top";
import TopContent from "@/components/profile/jaga-benefit-top-content";
import MainContent from "@/components/profile/jaga-benefit-main-content";
import { API, isLoggedIn } from "@/api/auth";
import { Link, navigate } from "gatsby";

const JagaBenefit = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const getData = async () => {
    if (isLoggedIn()) {
      try {
        setLoading(true);
        const response = await API.get("/api/client/o/get");

        if (!response.data.clientExId) {
          navigate("/klient/profil");
        }

        if (response.data.status == "error") logout();
        else setData(response.data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }
  };

  const changeStatus = () => {
    setReload(true);
  }

  useEffect(() => {
    getData();
    setReload(false);
  }, [reload == true]);

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Jaga benefit - Profil kibica">
          <HeaderOneTop />
          <HeaderOne />
          <BannerTop data={data} />
          <TopContent personalDataInfo={data ? data : false} changeStatus={changeStatus} />
          <MainContent
            personalDataFilled={data && data.clientExId ? true : false}
            benefit={data && data.benefit}
          />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default JagaBenefit;
