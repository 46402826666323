import React from "react";

import JagaTyperBg from "@/images/profile/typer-bg.jpg";

const JagaShopTopContent = () => {
  return (
    <section className="commonSection pb-3">
      <img src={JagaTyperBg} className="main-content-bg jaga-benefit-bg" />
      <div className="text-center jaga-benefit-top-content">
        <h2>BILETY / KARNETY</h2>
        <span>
          Poniżej przedstawiamy historię zakupionych przez Ciebie biletów i
          karnetów
        </span>
      </div>
    </section>
  );
};

export default JagaShopTopContent;
