import React from "react";
import Swal from "sweetalert2";
import { API } from "@/api/auth";

import JagaBenefitBg from "@/images/profile/benefit-bg.jpg";
import { Container, Col, Row } from "react-bootstrap";

const JagaBenefitTopContent = ({ personalDataInfo, changeStatus }) => {
  const use = (n, type) => {
    Swal.fire({
      title: type === 'discount' ? "Odbierz rabat" : "Odbierz nagrodę specjalną",
      html: type === 'discount' ? `Czy na pewno chcesz odebrać rabat w wysokości <span style='font-weight:bold;'>${n} zł</span> na zakupy w naszym sklepie online?` : 'Czy na pewno chcesz odebrać wybraną nagrodę specjalną?',
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Nie",
      confirmButtonText: "Tak",
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          let response = await API.post("/api/coupon/generate", {
            value: n,
            type: type
          });

          if (response.data.status == "success") {
            Swal.fire("Udało się", response.data.message, "success");
            changeStatus();
          }
          else Swal.fire("Wystąpił błąd", response.data.message, "error");
        } catch (err) {
          if (err.response.data.error_code == 403) {
            Swal.fire(
              "Wystąpił błąd",
              "Musisz być zalogowany, aby odebrać rabat.",
              "error"
            );
          }
        }
      }
    });
  }

  return (
    <section className="commonSection" style={{ padding: '80px 50px 0 50px' }}>
      <img src={JagaBenefitBg} className="main-content-bg jaga-benefit-bg" />
      <div className="text-center jaga-benefit-top-content">
        <h2>JAGA BENEFIT</h2>
        <span>Aktualna liczba Twoich punktów</span>
        <div className="rounded-circle jaga-benefit-points-box">
          <h3>
            {personalDataInfo ? Math.round(personalDataInfo.points) : "-"}
          </h3>
          <span>pkt</span>
        </div>
      </div>
      <Container className="w-75 mt-10" style={{ marginTop: '100px' }}>
        <Row className="justify-content-center">
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('10', 'discount')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <h3 style={{ wordSpacing: '0' }}>RABAT 10 ZŁ</h3>
                  <span>Aktywuj kod za 1000 pkt.</span>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('20', 'discount')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <h3 style={{ wordSpacing: '0' }}>RABAT 20 ZŁ</h3>
                  <span>Aktywuj kod za 2000 pkt.</span>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('50', 'discount')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <h3 style={{ wordSpacing: '0' }}>RABAT 50 ZŁ</h3>
                  <span>Aktywuj kod za 5000 pkt.</span>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('100', 'discount')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <h3 style={{ wordSpacing: '0' }}>RABAT 100 ZŁ</h3>
                  <span>Aktywuj kod za 10000 pkt.</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <h2 className="my-5 text-center">Katalog</h2>
        <Row className="justify-content-center">
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('1', 'item')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <span>Kolacja z prezesem</span>
                  <h3 className="mt-1" style={{ wordSpacing: '0' }}>10000 pkt.</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('2', 'item')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <span>Kolacja z dyrektorem Masłowskim</span>
                  <h3 className="mt-1" style={{ wordSpacing: '0' }}>10000 pkt.</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('3', 'item')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <span>Trening bramkarski z sztabem trenerskim</span>
                  <h3 className="mt-1" style={{ wordSpacing: '0' }}>7500 pkt.</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('4', 'item')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <span>Możliwość współkomentowania meczu rezerw</span>
                  <h3 className="mt-1" style={{ wordSpacing: '0' }}>5500 pkt.</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('5', 'item')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <span>Personalizacja koszulki</span>
                  <h3 className="mt-1" style={{ wordSpacing: '0' }}>5000 pkt.</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('6', 'item')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <span>Podpis zawodnika na dowolnym produkcie</span>
                  <h3 className="mt-1" style={{ wordSpacing: '0' }}>3500 pkt.</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('7', 'item')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <span>Podpis całej drużyny</span>
                  <h3 className="mt-1" style={{ wordSpacing: '0' }}>9000 pkt.</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('8', 'item')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <span>Życzenia urodzinowe w formie video od dowolnego zawodnika</span>
                  <h3 className="mt-1" style={{ wordSpacing: '0' }}>7500 pkt.</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('9', 'item')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <span>Życzenia urodzinowe na telebimie</span>
                  <h3 className="mt-1" style={{ wordSpacing: '0' }}>5000 pkt.</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('10', 'item')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <span>Udział w eskorcie z wyborem zawodnika</span>
                  <h3 className="mt-1" style={{ wordSpacing: '0' }}>7000 pkt.</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('11', 'item')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <span>Voucher na sektor SuperVIP</span>
                  <h3 className="mt-1" style={{ wordSpacing: '0' }}>14000 pkt.</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('12', 'item')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <span>Voucher na sektor VIP</span>
                  <h3 className="mt-1" style={{ wordSpacing: '0' }}>11000 pkt.</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3} className="col-12 mb-3" style={{ cursor: 'pointer' }} onClick={() => use('13', 'item')}>
            <div
              className={`singleTM tickets-card`}
            >
              <div
                className="tm_img tickets-card-bg"
                style={{ background: '#333', minHeight: '200px' }}
              >
                <div className="tm_overlay" style={{ background: 'linear-gradient(210.35deg, rgba(34, 34, 34, 0.1) 0%,#222222 65%)' }}></div>
                <div className="detail_TM justify-content-center align-items-center nav-cards-detail_TM">
                  <span>Voucher na sektor ULTRA/RODZINNY</span>
                  <h3 className="mt-1" style={{ wordSpacing: '0' }}>3500 pkt.</h3>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default JagaBenefitTopContent;
