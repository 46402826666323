import React from "react";

import BannerImgBox from "@/components/profile/banner-img-box";
import NavCards from "@/components/profile/nav-cards";

import PanelMainBg from "@/images/profile/panel-bg.jpg";

const MainContent = ({ data, personalDataFilled }) => {
  return (
    <section className="commonSection">
      <img src={PanelMainBg} className="main-content-bg" />
      <BannerImgBox ticketData={data} />
      <NavCards data={`default`} personalData={personalDataFilled} />
    </section>
  );
};

export default MainContent;
