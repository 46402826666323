import { Button, Modal } from "antd";
import React, { useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import ModalTicket from "@/components/modal-ticket";

import passBg from "@/images/bg/section/pass.jpg";

const BannerImgBox = ({ ticketData }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handlePrint = () => {
    setIsModalVisible(false);
  };
  const handleClose = () => {
    setIsModalVisible(false);
  };
  const componentRef = useRef();

  return (
    <>
      <Container className="profile-banner-img-box-container mb-5 mx-auto w-75">
        <Row
          className="pass text-center text-sm-center text-md-center text-lg-end"
          style={{ backgroundImage: `url('${passBg}')` }}
        >
          {ticketData && ticketData.actualTicket && (
            <Col>
              <button
                onClick={showModal}
                className="btn__effect profile-button profile-button-red"
              >
                WYŚWIETL {ticketData.actualTicket.typeName}
              </button>
            </Col>
          )}
        </Row>
      </Container>
      <Modal
        title="Bilet"
        visible={isModalVisible}
        onOk={handleClose}
        okText="Zamknij"
        onCancel={handlePrint}
        cancelText="Drukuj"
        className="ticket-modal"
        footer={[
          <ReactToPrint
            trigger={() => {
              return <Button>Drukuj</Button>;
            }}
            removeAfterPrint
            content={() => componentRef.current}
          />,
          <Button onClick={handleClose} type="primary">
            Zamknij
          </Button>,
        ]}
      >
        <ModalTicket
          ref={componentRef}
          actualTicketData={ticketData && ticketData.actualTicket}
        />
      </Modal>
    </>
  );
};

export default BannerImgBox;
